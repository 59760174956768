import { getImage } from 'gatsby-plugin-image';

export const findImageByBase = (allFile: any[], name: string) =>
  getImage(allFile.find((file) => file.base === name));

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
